import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { FirebaseContext } from "../components/Firebase";
import { getExpiryDate } from "../helpers/getExpiryDate";
import { Offline, Online } from "react-detect-offline";

import Seo from "../components/seo";
import Header from "../components/organisms/Header";
import Logo from "../components/atoms/Logo";
import Loader from "../components/atoms/Loader";
import Footer from "../components/organisms/Footer";
import Mountains from "../components/atoms/Mountains";
import ErrorCode from "../components/molecules/ErrorCode";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIdCardAlt, faCheck, faTimes, faExclamation, faBell } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';

import "../styles/base/_profile.scss";

const ProfilePage = () => {

  const { user, firebase } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [noticeMessage, setNoticeMessage] = useState('');
  const [displayNameValue, setDisplayNameValue] = useState('');
  const [formState, setFormState] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
     }, 2000);
    }
  }, [user]);

  // handle logout
  const handleLogoutClick = () => {
    firebase.logout()
    .then(() => navigate('/'));
    // .then(() => {
    //   window.location.reload();
    // });
  }

  // handle edit
  const handleEditClick = () => {
    setFormState(true);
    setDisplayNameValue(firebase.auth.currentUser.displayName);
  }

  // handle cancel
  const handleCancelClick = () => {
    setFormState(false);
  }

  // handle input change
  const handleInputChange = (e) => {
    e.persist();
    setErrorMessage('');
    setSuccessMessage('');
    setNoticeMessage('');
    setDisplayNameValue(document.querySelector('input[name="displayName"]').value);
  }

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (firebase.auth.currentUser.displayName !== displayNameValue) {
      firebase.updateDisplayName({
        displayName: displayNameValue
      }).then(() => {
        setLoading(false);
        setFormState(false);
        setSuccessMessage('Your full name has been successfully updated.');
      }).catch(error => {
        setLoading(false);
        setErrorMessage(error.message);
      });
    } else {
      setLoading(false);
      setNoticeMessage('No changes have been detected.');
    }
  }

  return (
    <>
      {!!loading ? (
        <Loader />
      ) : (
        <>
          <Seo title="My Profile" />
          {!!user && !!user.username && (
            <Header classes="header--hasLogo">
              <Logo link={true} classes="" />
            </Header>
          )}
          <Offline>
            <main className="static main--hasLogo">
              <div className="container">
                <div className="information">
                  <div className="message message--warning">
                    <div className="icon"><FontAwesomeIcon icon={ faExclamation } /></div>
                    <span>You are currently offline and you can't access your profile page at this time.</span>
                  </div>
                </div>
              </div>
            </main>
          </Offline>
          <Online>
            {!!user && !!user.email ? (
              <>
                <main className="static main--hasLogo">
                  <div className="container profile">
                    <div className="profile__heading">
                      <h1>My Profile</h1>
                      <button className="btn btn--small btn--light" onClick={handleLogoutClick}>Logout</button>
                    </div>
                    {/* {user.photoURL ? (
                      <div className="profile__image">
                        <img src={user.photoURL} alt={user.displayName} />
                      </div>
                    ) : ''} */}
                    {!!formState ? (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group">
                          <label htmlFor="displayName">Full Name</label>
                          <input onChange={handleInputChange} value={displayNameValue} name="displayName" type="text" required />
                        </div>
                        {!!errorMessage &&
                          <div className="message message--error">
                            <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                            <span>{errorMessage}</span>
                          </div>
                        }
                        {!!noticeMessage &&
                          <>
                            <div className="message message--notice">
                              <div className="icon"><FontAwesomeIcon icon={ faBell } /></div>
                              <span>{noticeMessage}</span>
                            </div>
                          </>
                        }
                        <div className="btn-group">
                          <button className="btn" onClick={handleCancelClick}>Cancel</button>
                          <button className="btn btn--black" type="submit">Update Profile</button>
                        </div>
                      </form>
                    ) : (
                      <>
                        <div className="profile__details">
                          <div className="profile__details__item">
                            <span className="profile__details__icon"><FontAwesomeIcon icon={ faUser } size={ 'lg' } /></span>
                            <span>{user.username}</span>
                          </div>
                          <div className="profile__details__item">
                            <span className="profile__details__icon"><FontAwesomeIcon icon={ faIdCardAlt } size={ 'lg' } /></span>
                            <span>{firebase.auth.currentUser.displayName}</span>
                          </div>
                          <div className="profile__details__item">
                            <span className="profile__details__icon">
                              {user.providerData[0].providerId === 'google.com' ? (
                                <FontAwesomeIcon icon={ faGoogle } size={ 'lg' } />
                              ): user.providerData[0].providerId === 'facebook.com' ? (
                                <FontAwesomeIcon icon={ faFacebook } size={ 'lg' } />
                              ) : ''}
                            </span>
                            <span>{firebase.auth.currentUser.email}</span>
                          </div>
                        </div>
                        {!!successMessage &&
                          <>
                            <div className="message message--success">
                              <div className="icon"><FontAwesomeIcon icon={ faCheck } /></div>
                              <span>{successMessage}</span>
                            </div>
                          </>
                        }
                        <button className="btn btn--black" onClick={handleEditClick}>Edit Profile</button>
                        <hr />
                        <div className="information">
                          <h3>Current Access</h3>
                          {!!user.montaguAccess ? (
                            <p>
                              <strong>Montagu</strong><br />
                              {`Expires ${getExpiryDate(user.montaguUntil)}`}
                            </p>
                          ) : (
                            <p>You have no available guides.</p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </main>
              </>
            ) : (
              <>
                <main className="center-flex">
                  <div className="container">
                    <Logo link={false} classes="logo--large" />
                    <ErrorCode number="403" />
                    <Mountains />
                  </div>
                </main>
              </>
            )}
          </Online>
          {!!user && !!user.username && (
            <Footer />
          )}
        </>
      )}
    </>
  )
}

export default ProfilePage;