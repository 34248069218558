import React from "react";
import { Link } from "gatsby";

import "./ErrorCode.scss";

const ErrorCode = ({number}) => (
  <>
    <div className="information error-code">
      {number === '403' &&
        <>
          <div className="error-code__number">{number}</div>
          <p>The page you're trying to access has restricted access.</p>
        </>
      }
      {number === '404' &&
        <>
          <div className="error-code__number">{number}</div>
          <p>You just hit a route that doesn't exist... the sadness.</p>
        </>
      }
    </div>
    <Link to="/" className="btn btn--black">Return Home</Link>
  </>
);

export default ErrorCode;