import React from "react";
import loadable from "@loadable/component";

import './Mountains.scss';

const Mountains = () => {
  let Element = loadable(() => import('../../assets/mountains.inline.svg'));

  return (
    <div className="mountains">
      <Element />
    </div>
  )
}

export default Mountains;